import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../partials/Header';
import Footer from '../partials/Footer';
import Arrow from '../utils/Arrow';
import Waves from '../utils/Waves';

function Status() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header />

      <main className="flex-grow px-8 w-screen">
        <div className="hidden sm:block overflow-x-hidden">
          <Waves width="1500" />
        </div>

        <section className="relative">
          <div className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className="pt-20 sm:pt-40 pb-20">
              <div className="max-w-3xl mx-auto text-center">
                <h1 className="h1 mb-4 font-headings" data-aos="fade-up" data-aos-delay="250">Maverick backend status</h1>
                <p className="text-2xl text-gray-200" data-aos="fade-up" data-aos-delay="250">🟢 </p>
                <div className="mt-8" data-aos="fade-up" data-aos-delay="300">
                  <Link className="btn text-black font-standard font-medium bg-white hover:bg-gray-200 inline-flex items-center" to="/">
                    <span>Back home</span>
                    <Arrow />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
}

export default Status;