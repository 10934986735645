import React from 'react';

const navigation = {
  main: [
    { name: 'Home', href: '/' },
    { name: 'Product Guide', href: '/guide' },
    { name: 'FAQs', href: '/faqs' },
    { name: 'Contact', href: 'mailto:team@withmaverick.com', plausibleClass: "plausible-event-name=ContactClicked" },
    { name: 'Terms Of Service', href: 'https://www.lattice.dev/tos', external: true },
    { name: 'Privacy Policy', href: 'https://www.lattice.dev/privacy', external: true },
  ]
}

export default function Footer(props) {
  return (
    <footer className="relative" data-aos="fade-up" data-aos-delay="200">
      <div className="max-w-6xl mx-auto pb-24 px-4 overflow-hidden sm:px-6 lg:px-8 pt-16">
        <nav className="flex flex-wrap justify-center" aria-label="Footer">
          {navigation.main.map((item) => (
            <div key={item.name} className="px-5 py-2" data-aos="fade-up" data-aos-delay="350">
              <a href={item.href} className={"text-gray-200 hover:text-gray-400 font-standard font-medium transition-colors ease-in-out delay-250 " + item.plausibleClass} target={item.external ? "_blank" : ""}>
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <a className="plausible-event-name=ProductHuntClicked" href="https://www.producthunt.com/products/maverick/reviews?utm_source=badge-product_review&utm_medium=badge&utm_souce=badge-maverick" target="_blank" rel="noopener noreferrer">
          <img className="rounded-xl mt-6 mx-auto rounded-xl border border-gray-800" src="https://api.producthunt.com/widgets/embed-image/v1/product_review.svg?product_id=539390&theme=dark" alt="Product Hunt" data-aos="fade-up" data-aos-delay="300" />
        </a>
        <p data-aos="fade-up" data-aos-delay="250" className="mt-6 text-center text-white opacity-50">Maverick, the copilot for Copilot.<br/>&copy; 2023 <a rel="noopener noreferrer" href="https://lattice.dev/" target="_blank" className="underline">Lattice Data LLC</a>. All rights reserved.</p>
      </div>
    </footer>
  )
}
