import React from 'react';

import Checkmark from '../utils/Checkmark';
import ContentLink from '../utils/ContentLink';

export default function HeroProductGuide() {
  return (
    <section className="relative max-w-4xl mx-auto pt-20 sm:pt-48">
      <h1 className="text-4xl sm:text-6xl font-extrabold pb-12 font-headings text-center" data-aos="fade-down" data-aos-delay="200">Product Guide</h1>
      <div className="pt-6">
        <h2 className="text-3xl sm:text-4xl font-extrabold mb-4 font-headings" data-aos="fade-down" data-aos-delay="200">Ensuring quality at speed</h2>

        <ul className="max-w-sm sm:max-w-md mx-auto md:max-w-none text-gray-200 mt-8" data-aos="fade-down" data-aos-delay="200">
          <li className="flex items-center mb-2">
            <Checkmark inverted={true} />
            <span className="ml-2"><b><ContentLink href="#overview" anchor="Overview" /></b>: Maverick reads and comments on new PRs you submit in real-time to catch issues you missed.</span>
          </li>
          <li className="flex items-center mb-2">
            <Checkmark inverted={true}  />
          <span className="ml-2"><b><ContentLink href="#quickstart" anchor="Quickstart" /></b>: Installation and basic usage instructions.</span>
          </li>
          <li className="flex items-center mb-2">
            <Checkmark inverted={true}  />
            <span className="ml-2"><b><ContentLink href="#configurations" anchor="Configurations" /></b>: Personalizing how Maverick behaves in your repositories. </span>
          </li>
        </ul>
      </div>
    </section>
  );
}
