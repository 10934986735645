import React from 'react';
import Header from '../partials/Header';
import HeroFAQs from '../partials/HeroFAQs';
import Footer from '../partials/Footer';
import FAQsAccordion from '../partials/FAQsAccordion';
import Cta from '../partials/Cta';
import EmailLink from '../partials/EmailLink';
import ContentLink from '../utils/ContentLink';
import BlueLine from '../utils/BlueLine';
import Gist from "react-gist";
import Chip from '@mui/material/Chip';
import Checkmark from '../utils/Checkmark';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import background from "../assets/svg/wobbling-particles.svg";

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
    }
  },
});

const content = [
  {
    title: 'Product',
    id: 'product',
    questions: [
      {
        question: "How can I get started?",
        answer: <div className="text-base text-gray-300">
          <br/>
          <p className="mb-2">Head over to <ContentLink external={true} href={process.env.REACT_APP_INSTALL_URL} anchor="the GitHub marketplace" /> for installation. You can specify the repos that you want to connect to in the access and permissions page of the installation. 
          Maverick will then automatically listen for new pull requests on those repos and review them in real-time.</p>
          <br/>
          <img src={require("../assets/img/install-app.png")} className="mx-auto w-100 h-100 rounded-sm" alt="Install" />
          <br/>
        </div>
      },
      {
        question: "How can I turn off auto-reviews and opt to manually ask for reviews instead?",
        answer: <ThemeProvider theme={theme} className="text-base text-gray-300">
          <br/>
          <p className="mb-2">You can change Maverick's default settings by adding and modifying a <Chip label="maverick.config.json" color="primary" variant="outlined" /> Maverick config file, stored in the root directory of your repo. To turn off auto-review, simply add the following line to your config file:</p>
          <br/>
          <div className="-m-2">
            <Gist id="f5569ed4f20f1e53b7879477f148f683" />
          </div>
          <p>More comprehensive instructions for using the Maverick config file can be found in the <ContentLink href="/guide#configurations" anchor="Maverick product guide"/>.</p>
          <br/>
          <p>Lastly, to then manually ask Maverick to review a pull request, simply mention '@WithMaverick' in an issue comment on your PR. Maverick will flag this comment and trigger a PR review as a result. See a demo of this feature in action in the <ContentLink href="/guide#configurations" anchor="Maverick product guide"/>.</p>
          <br/>
        </ThemeProvider>
      },
      {
        question: "How can I prevent Maverick from auto-reviewing specific target branches?",
        answer: <ThemeProvider theme={theme} className="text-base text-gray-300">
          <br/>
          <p className="mb-2">This can be done by adding a field to the <Chip label="maverick.config.json" color="primary" variant="outlined" /> file in the root directory of your repo:</p>
          <br/>
          <div className="-m-2">
            <Gist id="c2c02df4e99ba15fd46da7a9360bdd79" />
          </div>
          <p>More comprehensive instructions for using the Maverick config file can be found in the <ContentLink href="/guide#configurations" anchor="Maverick product guide"/>.</p>
          <br/>
          <p>As always, you can still manually ask Maverick to review a pull request by tagging '@WithMaverick' in an issue comment on the PR.</p>
          <br/>
        </ThemeProvider>
      },
      {
        question: "How can I filter out pull requests created by bots?",
        answer: <ThemeProvider theme={theme} className="text-base text-gray-300">
          <br/>
          <p className="mb-2">This can be done by adding fields to <Chip label="maverick.config.json" color="primary" variant="outlined" /> to blacklist specific pull request title patterns and/or maintain an explicit whitelist of non-bot users:</p>
          <br/>
          <div className="-m-2">
            <Gist id="94bd1950a8896ad878e03a075eb1fd50" />
          </div>
          <br/>
          <p>More comprehensive instructions for using the Maverick config file can be found in the <ContentLink href="/guide#configurations" anchor="Maverick product guide"/>.</p>
          <br/>
          <p>As always, you can still manually ask Maverick to review a pull request by tagging '@WithMaverick' in an issue comment on the PR.</p>
          <br/>
        </ThemeProvider>
      },
      {
        question: "What machine languages does Maverick support?",
        answer: <div className="text-base text-gray-300">
          <br/>
          <p className="mb-2">Today, Maverick supports Python, JavaScript, Go, Perl, PHP, Ruby, Swift, TypeScript, SQL, and Shell scripts. We chose this list based on the the set of languages OpenAI's models provide the best support for. You can read more on that topic <ContentLink external={true} href="https://platform.openai.com/docs/guides/code/introduction" anchor="here"/>.</p>
          <p className="mb-2">We plan to support more languages in the near future, including Rust, C, and C++.</p>
          <br/>
        </div>
      },
      {
        question: "How do I provide feedback to Maverick's PR feedback?",
        answer: <div className="text-base text-gray-300">
          <br/> 
          <p className="text-base text-gray-300">Maverick listens to your feedback on a granular level in order to gradually refine its core learning models over time. To provider positive or negative reinforcement on Maverick's individual comments, react with GitHub's <span role="img" aria-label="like">&#128077;</span> or <span role="img" aria-label="dislike">&#128078;</span> reactions to let Maverick know how it can do better.</p>
          <br/>
        </div>
      },
      {
        question: "How do I report bugs and provide product feedback to the Maverick Team?",
        answer: <div className="text-base text-gray-300">
          <br/> 
          <p className="text-base text-gray-300">Please shoot us an email at <EmailLink />. We really appreciate feedback of any kind and would love to hear from you!</p>
          <br/>
        </div>
      },
    ]
  },
  {
    title: 'Pricing',
    id: 'pricing',
    questions: [
      {
        question: "How much does Maverick cost?",
        answer: <div className="text-base text-gray-300">
          <br/> 
          <p className="text-base text-gray-300">Today, Maverick is completely free. But if you'd like to give back we'd love to hear your honest feedback as we iterate and expand the product!</p>
          <br/>
        </div>
      },
      {
        question: "Will you ever charge for Maverick in the future?",
        answer: <div className="text-base text-gray-300">
          <br/> 
          <p className="text-base text-gray-300">Yes. We plan on adding paid service tiers with additional functionality soon.</p>
          <p className="text-base text-gray-300">That being said, we're committed to keeping a free tier version Maverick free for developers indefinitely.</p>
          <br/>
        </div>
      },
    ]
  },
  {
    title: 'Privacy and Permissions',
    id: 'privacy',
    questions: [
      {
        question: "Does Maverick store my code?",
        answer: <div className="text-base text-gray-300">
          <br/> 
          <p className="mb-2">No! Maverick does not store any of your code in our systems. The only user data we store is the <span role="img" aria-label="like">&#128077;</span> <span role="img" aria-label="dislike">&#128078;</span> feedback you provide on Maverick's comments.</p>
          <p className="mb-2">That being said, a core part of Maverick's backend functionality involves making API calls to OpenAI's platform. OpenAI's platform maintains its own data retenion policy, which is described <ContentLink external={true} href={process.env.REACT_APP_INSTALL_URL} anchor="here"/>.</p>
          <br/> 
        </div>
      },
      {
        question: "What type of access to my GitHub account does Maverick need?",
        answer: <div className="text-base text-gray-300">
          <br/> 
          <p className="text-base text-gray-300">Maverick requests and receives limited permissions to repos you install the Maverick app in during the installation process. Most of these permissions are read-only, with a few necessary write permissions so Maverick can provide you feedback via comments and other form factors.</p>
          <br/> 
          <p className="text-base text-gray-300">Specifically, Maverick requests and receives these permissions through GitHub with your consent:</p>
          <ul className="max-w-sm sm:max-w-md mx-auto md:max-w-none text-gray-200 mt-2">
            <li className="flex items-center mb-2">
              <Checkmark inverted={true} />
              <span className="ml-2"><b>Read</b> access to code, commit statuses, merge queues, metadata, packages, pages, repository hooks, and repository projects.</span>
            </li>
            <li className="flex items-center mb-2">
              <Checkmark inverted={true}  />
            <span className="ml-2"><b>Read</b> and <b>write</b> access to discussions, issues, and pull requests.</span>
            </li>
          </ul>
          <br/>
        </div>
      },
      {
        question: "Can I customize Maverick's access to my code at a more granular level?",
        answer: <div className="text-base text-gray-300">
          <br/> 
          <p className="text-base text-gray-300">If you have any privacy or related concerns, we'd be happy to work with you to make sure your needs are met. Please contact us at <EmailLink /> to discuss. </p>
          <br/>
        </div>
      }
    ]
  }
]

export default function FAQs() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden bg-cover bg-no-repeat bg-fixed bg-top" style={{ backgroundImage: `url(${background})` }}>
      <Header />

      <main className="relative px-8 sm:px-0 flex-grow overflow-hidden w-screen">
        <div className="bg-gradient-to-b from-black">
          <HeroFAQs content={content} />
        </div>

        {content.map((section) => (
          <>
            <div id={section.id} />
            <BlueLine classes="relative mb-12 mt-16 max-w-4xl" loadDelay="300" />
            <FAQsAccordion title={section.title} faqs={section.questions} />
          </>
        ))}
        <div className="pb-12 sm:pb-20" />

        <Cta />
      </main>

      <Footer />
    </div>
  );
}
