import React from 'react';
import SignupButton from '../utils/SignupButton';
import rainbow from "../assets/svg/rainbow.svg";

function Cta() {
  return (
    <section>
      <div className="max-w-6xl mx-auto" data-aos="zoom-y-out" data-aos-delay="450">
        <div className="bg-gradient-to-r bg-cover rounded py-10 px-8 md:py-16 md:px-12 shadow-2xl" style={{ backgroundImage: `url(${rainbow})` }}>
          <div className="flex flex-col lg:flex-row justify-between items-center">
            <div className="pl-6 text-left">
              <h3 className="h3 text-3xl sm:text-4xl text-white mb-8 font-headings font-medium">Ready to give Maverick a test flight?</h3>
              <SignupButton />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Cta;
