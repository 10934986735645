import React from 'react';

import Header from '../partials/Header';
import HeroProductGuide from '../partials/HeroProductGuide';
import ProductGuideDetail from '../partials/ProductGuideDetail';
import Footer from '../partials/Footer';
import Cta from '../partials/Cta';
import background from "../assets/svg/wobbling-particles.svg";

function ProductGuide() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden bg-cover bg-no-repeat bg-fixed bg-top" style={{ backgroundImage: `url(${background})` }}>
      <Header />
      <main className="relative px-8 sm:px-0 flex-grow overflow-hidden w-screen">
        <div className="relative bg-gradient-to-b from-black">
          <HeroProductGuide />
        </div>
        <ProductGuideDetail />
        <Cta />
      </main>
      <Footer hideWaves={true} />
    </div>
  );
}

export default ProductGuide;