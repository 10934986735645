import React from 'react';
import SignupButton from '../utils/SignupButton';
import BlueText from '../utils/BlueText';
import background from "../assets/svg/digital-rain.svg";
import ProductOverview from '../partials/ProductOverview';
import BlueLine from '../utils/BlueLine';

function HeroHome() {

  return (
    <section className="bg-cover bg-no-repeat bg-fixed bg-top" style={{ backgroundImage: `url(${background})` }}>
      <div style={{backgroundColor: "rgba(0, 0, 0, 0.5)"}}>
        <div className="pt-20 sm:pt-32 pb-10 md:pt-40 md:pb-16 max-w-6xl mx-auto mb-10 z-10">
          <div className="max-w-2xl mx-auto text-center">
            <img src={require("../assets/svg/logo-white.svg")} className="mx-auto w-16 h-16 sm:w-24 sm:h-24" alt="Maverick" data-aos="fade-down" data-aos-delay="200" />
            <h1 className="text-3xl h1 sm:text-6xl font-extrabold font-headings font-extrabold text-center" data-aos="fade-down" data-aos-delay="250"><BlueText text="Maverick" /></h1>
            <h2 className="text-2xl h2 sm:text-5xl mx-auto font-extrabold mb-2 font-headings text-center" data-aos="fade-down" data-aos-delay="300"><BlueText text="The copilot for Copilot." light={true} /></h2>
            <h3 className="text-xl h3 sm:text-2xl mx-auto font-medium mb-10 font-standard text-center" data-aos="fade-down" data-aos-delay="300">An incremental layer of automated code review for your <span className="font-semibold">GitHub</span> pull requests <span className="font-normal text-gray-200">to catch more of the small issues that slip through the cracks. <span className="font-medium">100% free.</span></span></h3>
            <div data-aos="fade-down" data-aos-delay="350">
              <SignupButton />
            </div>
          </div>
        </div>

        <BlueLine classes="max-w-5xl" loadDelay="300" />

        <ProductOverview />
      </div>
    </section>
  );
}

export default HeroHome;
