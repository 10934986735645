import React from 'react';
import BlueLine from '../utils/BlueLine';
import EmailLink from './EmailLink';
import ContentLink from '../utils/ContentLink';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Gist from "react-gist";

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
    }
  },
});

function BigSpace() {
  return <span>&nbsp;&nbsp;</span>
}

export default function ProductGuideDetail() {

  return (
    <ThemeProvider theme={theme}>
      <div id="overview" />
      <section className="relative pb-12">
        <BlueLine classes="max-w-5xl mb-12 mt-16" loadDelay="150"/>

        <div className="max-w-4xl mx-auto text-base text-gray-300" data-aos="fade-down" data-aos-delay="250">
          <h3 className="text-2xl sm:text-3xl font-extrabold font-headings pb-4 text-white" data-aos="fade-down" data-aos-delay="250">Overview</h3>
          <p className="mb-2">For developers, cycle time is the enemy of speed. The average developer initiates 2-10 pull requests per week. Average time to merge can be anywhere from 3 to 15 days and further work is often blocked by PRs waiting for review. It can be frustrating if the reviewer then finds issues that could have been fixed much earlier, but now the cycle has to repeat and progress is again stalled.</p>
          <br />
          <p className="mb-2">Maverick allows you to accelerate pull request cycles. It understands the context of your pull requests by ingesting relevant code and user-provided comments. In return, it provides its opinion about the changes, along with actionable suggestions for improvement.</p>
          <br />
          <p className="mb-2">Maverick is able to:</p>
          <ol className="list-decimal ml-4 mb-2">
            <li>Spot and suggest corrections for unintentional bugs.</li>
            <li>Call out repetitive code and opportunities for better abstractions.</li>
            <li>Identify redundant imports, variables, and functions.</li>
            <li>Provide stylistic comments to help improve the readability of your code.</li>
          </ol>
          <br />
          <p className="mb-2">We're excited to continue improving Maverick's quality and functionality. Please shoot us an email at <EmailLink /> with any requests or feedback.</p>
        </div>

        <div id="quickstart" />
        <BlueLine classes="max-w-5xl mb-12 mt-16" loadDelay="250"/>

        <div className="max-w-4xl mx-auto text-base text-gray-300" data-aos="fade-down" data-aos-delay="250">
          <h3 className="text-2xl sm:text-3xl font-extrabold font-headings pb-4 text-white" data-aos="fade-down" data-aos-delay="250">Quickstart</h3>
          <p className="mb-2">Head over to <ContentLink anchor="GitHub" href={process.env.REACT_APP_INSTALL_URL} external={true} /> to install the Maverick GitHub app. Choose the repos you'd like Maverick to monitor while you're at it. Note that if the repo is owned by the organization, you should ensure that Maverick is installed by the organization's account instead of the personal account. Once installed, Maverick will automatically review any new pull requests submitted to those repos.</p>

          <div className="relative flex justify-center items-center my-10">
            <iframe title="installation video" width="640" height="416" src={process.env.REACT_APP_INSTALLATION_VIDEO_URL} frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen="true"/>
          </div>
          
          <p className="mb-2">Separately, you can always manually ask Maverick to review any pull request at any time by tagging '@WithMaverick' in an issue comment on the PR.</p>
          
          <div className="relative flex justify-center items-center my-10">
            <iframe width="640" height="416" title="trigger video" src={process.env.REACT_APP_TRIGGER_VIDEO_URL} frameborder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowfullscreen="true"/>
          </div>

          <br/>
          <p className="mb-2">Lastly, you can request further clarifications from Maverick by replying one of Maverick's comments and tagging '@WithMaverick'. Note that the Github website requires you to refresh the page in order to see Maverick's response.</p>
          <br/>
          <p className="mb-2">Note that Maverick currently supports the following file types:</p>
          <Stack className="mb-2" direction="row" spacing={1}>
            <Chip label=".py" color="primary" variant="outlined" />
            <Chip label=".ts" color="primary" variant="outlined" />
            <Chip label=".tsx" color="primary" variant="outlined" />
            <Chip label=".js" color="primary" variant="outlined" />
            <Chip label=".jsx" color="primary" variant="outlined" />
            <Chip label=".php" color="primary" variant="outlined" />
            <Chip label=".sql" color="primary" variant="outlined" />
            <Chip label=".csh" color="primary" variant="outlined" />
            <Chip label=".sh" color="primary" variant="outlined" />
            <Chip label=".rb" color="primary" variant="outlined" />
            <Chip label=".go" color="primary" variant="outlined" />
            <Chip label=".pl" color="primary" variant="outlined" />
          </Stack>
          <p className="mb-2">By default, it will ignore files with other extensions. We chose this list of file types based on the the set of languages OpenAI's models provide the best support for. You can read more on that topic <ContentLink external={true} href="https://platform.openai.com/docs/guides/code/introduction" anchor="here"/>.</p>
        </div>

        <div id="configurations" />
        <BlueLine classes="max-w-5xl mb-12 mt-16" loadDelay="250"/>

        <div className="max-w-4xl mx-auto text-base text-gray-300" data-aos="fade-down" data-aos-delay="250">
          <h3 className="text-2xl sm:text-3xl font-extrabold font-headings pb-4 text-white" data-aos="fade-down" data-aos-delay="250">Configurations</h3>
          <p className="mb-2">You can configure Maverick's behavior with respect to all pull requests submitted to a repo by adding a <Chip label="maverick.config.json" color="primary" variant="outlined" /> file to the root directory of that repo. Currently, Maverick config files support the following configuration fields:</p>
          <br />
          <ul className="list-decimal ml-4">
            <li className="mb-4"><Chip label="auto_review_all_prs" color="primary" variant="outlined" /><BigSpace />turns on/off auto-review for PRs. If not set by you, this configuration defaults to true.</li>
            <li className="mb-4"><Chip label="supported_file_extensions" color="primary" variant="outlined" /><BigSpace />limits reviews to files with specific extensions. If not set by you, this configuration defaults to the list of file extensions above.</li>
            <li className="mb-4"><Chip label="ignored_directories" color="primary" variant="outlined" /><BigSpace />turns off auto-reviews for files in certain directories (eg. tests, etc). If not set by you, this configuration defaults to ["node_modules", "__pycache__", "venv"].</li>
            <li className="mb-4"><Chip label="supported_pr_creators" color="primary" variant="outlined" /><BigSpace />limits auto-reviews to pull requests created by specific authors.</li>
            <li><Chip label="blacklisted_pr_title_regexes" color="primary" variant="outlined" /><BigSpace />turns off auto-reviews for PRs whose titles match specified regex patterns. This feature is commonly used to filter out routine PRs submitted by bots.</li>
          </ul>
          <br />
          <p className="mb-2">Here's a sample valid <Chip label="maverick.config.json" color="primary" variant="outlined" /> file for your reference:</p>
          <div className="m-2">
            <Gist id="f40d1b27903388b4bf9a509d8c4a95af" frameborder={5} />
          </div>
        </div>

      </section>
    </ThemeProvider>
  );
}
