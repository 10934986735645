import React, { useState, useEffect } from 'react';

import Logo from './Logo';
import SignupButton from '../utils/SignupButton';

function Header(props) {

  const [top, setTop] = useState(true);

  // detect whether user has scrolled the page down by 10px 
  useEffect(() => {
    const scrollHandler = () => {
      if (window.pageYOffset > 10) {
        setTop(false)
      } else {
        setTop(true)
      } 
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);  

  return (
    <header className={`hidden sm:block overflow-hidden fixed w-full z-30 transition duration-300 left-0 ease-in-out border-gray-800 w-screen ${!top && 'bg-black bg-opacity-80 shadow-lg border-b'}`}>
      <div className="max-w-6xl mx-auto" data-aos="fade-down" data-aos-delay="200">

        <div className="justify-between h-20 items-center flex flex-row">
          <div className="justify-between h-20 items-center flex flex-row">
            <Logo white={true} /> 
            <a className="ml-8 font-standard font-medium text-lg hover:text-gray-300 transition-all ease-in-out delay-250" href="/guide">Product Guide</a>
            <a className="ml-8 font-standard font-medium text-lg hover:text-gray-300 transition-all ease-in-out delay-250" href="/faqs">FAQs</a>
            <a className="plausible-event-name=ContactClicked ml-8 font-standard font-medium text-lg hover:text-gray-300 transition-all ease-in-out delay-250" href="mailto:team@withmaverick.com" target="blank_">
              Contact
            </a>
          </div>

          <div className="flex flex-row-reverse">
            <SignupButton short={true} />
            <a className="plausible-event-name=ProductHuntClicked" href="https://www.producthunt.com/products/maverick/reviews?utm_source=badge-product_review&utm_medium=badge&utm_souce=badge-maverick" target="_blank" rel="noopener noreferrer"><img className="h-10 mr-2" src="https://api.producthunt.com/widgets/embed-image/v1/product_review.svg?product_id=539390&theme=neutral" alt="Product Hunt" /></a>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
