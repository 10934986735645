import React from 'react';

import ContentLink from '../utils/ContentLink';

export default function ProductOverview() {

  return (
    <div className="w-full bg-gradient-to-t from-black">
      <div className="pt-20 max-w-4xl mx-auto pb-12 sm:pb-24">
        <h2 className="text-3xl sm:text-4xl font-extrabold font-headings text-center mx-auto mb-12 sm:mb-20" data-aos="fade-down" data-aos-delay="200">How it works</h2>

        <div className="hidden sm:block">
          <div className="mx-auto grid grid-cols-1 sm:grid-cols-2 grid-flow-col-dense gap-24 pb-24" data-aos="fade-right" data-aos-delay="250">
            <div>
              <img src={require("../assets/svg/github.svg")} className="w-20 h-20 mb-2" alt="Install" />
              <h3 className="h3 font-headings" data-aos="fade-right" data-aos-delay="250">Install the app</h3>
              <p className="mt-4 text-lg text-gray-300 mb-4" data-aos="fade-right" data-aos-delay="300">
                Head over to the <ContentLink anchor="GitHub Marketplace" href={process.env.REACT_APP_INSTALL_URL} external={true} /> to install the Maverick app. Choose the repos you'd like Maverick to monitor while you're at it.
              </p>
            </div>
            <img src={require("../assets/img/install-app.png")} className="mx-auto mb-4 w-100 h-100 hover:scale-[1.3] transition-all duration-300 rounded-sm" alt="Install" />
          </div>

          <div className="mx-auto grid grid-cols-2 grid-flow-col-dense gap-24 pb-24" data-aos="fade-left" data-aos-delay="200">
            <img src={require("../assets/img/open-pr.png")} className="mx-auto mb-4 w-100 h-100 hover:scale-[1.3] transition-all duration-300 rounded-sm" alt="Pull request" />
            <div>
              <div className="mt-2">
                <img src={require("../assets/svg/pull-request.svg")} className="mb-4 w-12 h-12" alt="Pull request" />
                <h3 className="h3 font-headings" data-aos="fade-left" data-aos-delay="250">Submit PRs</h3>
                <p className="mt-4 text-lg text-gray-300" data-aos="fade-left" data-aos-delay="300">
                  Submit pull requests per your usual development cycle. Maverick will listen for new pull requests on your selected repos.
                </p>
              </div>
            </div>
          </div>

          <div className="mx-auto grid grid-cols-2 grid-flow-col-dense gap-24" data-aos="fade-right" data-aos-delay="250">
            <div>
              <img src={require("../assets/svg/comment.svg")} className="mb-4 w-16 h-16" alt="Feedback" />
              <h3 className="h3 font-headings" data-aos="fade-right" data-aos-delay="250">Get feedback</h3>
              <p className="mt-4 text-lg text-gray-300" data-aos="fade-right" data-aos-delay="300">
              Upon noticing a new pull request, Maverick will automatically read the relevant code and review the PR, providing its feedback to you via GitHub review comments.
              </p>
            </div>
            <img src={require("../assets/img/pr-comment.png")} className="mx-auto mb-4 w-100 h-100 hover:scale-[1.3] transition-all duration-300 rounded-sm" alt="Feedback" />
          </div>
        </div>

        <div className="block sm:hidden">
          <div data-aos="fade-down" data-aos-delay="200">
            <img src={require("../assets/svg/github.svg")} className="w-8 h-8 mb-4" alt="Install" data-aos="fade-right" data-aos-delay="250"/>
            <h4 className="h4 font-headings" data-aos="fade-right" data-aos-delay="250">Install the app</h4>
            <p className="mt-4 text-lg text-gray-300 mb-4" data-aos="fade-right" data-aos-delay="300">
              Head over to the <ContentLink anchor="GitHub Marketplace" href={process.env.REACT_APP_INSTALL_URL} external={true} /> to install the Maverick app. Choose the repos you'd like Maverick to monitor while you're at it.
            </p>
          </div>

          <div className="mt-12" data-aos="fade-down" data-aos-delay="250">
            <img src={require("../assets/svg/pull-request.svg")} className="mb-4 w-8 h-8 rotate-180" alt="Pull request" data-aos="fade-left" data-aos-delay="200"/>
            <h4 className="h4 font-headings" data-aos="fade-right" data-aos-delay="250">Submit PRs</h4>
            <p className="mt-4 text-lg text-gray-300" data-aos="fade-left" data-aos-delay="300">
              Submit pull requests per your usual development cycle. Maverick will listen for new pull requests on your selected repos.
            </p>
          </div>

          <div className="mt-12" data-aos="fade-down" data-aos-delay="300">
            <img src={require("../assets/svg/comment.svg")} className="w-12 h-12 " alt="Feedback" data-aos="fade-right" data-aos-delay="200"/>
            <h4 className="h4 font-headings" data-aos="fade-right" data-aos-delay="250">Get feedback</h4>
            <p className="mt-4 text-lg text-gray-300" data-aos="fade-right" data-aos-delay="300">
              Upon noticing a new pull request, Maverick will automatically read the relevant code and review the PR, providing its feedback to you via GitHub review comments.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
