import React from 'react';

import Header from '../partials/Header';
import HeroHome from '../partials/HeroHome';
import Cta from '../partials/Cta';
import Footer from '../partials/Footer';

export default function Home() {
  return (
    <div className="flex flex-col min-h-screen overflow-hidden w-screen">
      <Header />
      <main className="px-8 sm:px-0 flex-grow overflow-hidden w-screen">
        <HeroHome />
        <Cta />
      </main>
      <Footer />
    </div>
  );
}
