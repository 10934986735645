import React from 'react';
import { Link } from 'react-router-dom';

function Logo(props) {
  return (
  	<Link to="/" className="flex" aria-label="Home">
      <img src={props.white ? require("../assets/svg/logo-white.svg") : require("../assets/svg/logo.svg")} className="w-10 h-10" alt="Maverick" />
      <h4 className="pl-2 self-start h4 text-white font-headings">Maverick</h4>
  	</Link>
  );
}

export default Logo;